import { ImageObject } from "./type";

export const enum ConstactInfo {
    phone1 = '090 123 0522',
    phone2 = '090 440 3289',
    infoEmail = 'info@lebruder.com',
}

export const enum MessageType {
    ERROR,
    SUCCESS
}
export const imageList: ImageObject[] = [
    {
        path: "https://lh3.googleusercontent.com/p/AF1QipP1wCFavpoBhq-KktXgaQ-4A22nYR6U9QVBFoSl=s1360-w1360-h1020",
        title: "Home 1",
    },
    {
        path: "https://lh3.googleusercontent.com/p/AF1QipPxt-Hb5WXXlqBJBO_0xh5g4w_UX41Dx7VKF-kR=s1360-w1360-h1020",
        title: "Home 1",
    },
    {
        path: "https://www.hpdconsult.com/wp-content/uploads/2019/10/HPD-Construction-and-Interiors.jpg",
        title: "Home 1",
    },
    {
        path: "https://lh3.googleusercontent.com/p/AF1QipPeGlPamqvkfUgtIuGce2pZ6BqSLRLj_xQGJC3P=s1360-w1360-h1020",
        title: "Home 1",
    },
    {
        path: "https://www.saykooliew.com/images/slideshow-home/img-slideshow-2021-07.jpg",
        title: "Home 1",
    },
];