import styles from "../assets/style/Navbar.module.scss";

export default function Navbar() {
  return (
    <nav className={styles.nav}>
      <a href="#" className="site-title">
      </a>
      <ul>
        <li>
          <a href="/">Trang chủ</a>
          </li>
          <li>
          <a href="/product">Sản phẩm</a>
        </li>
      </ul>
    </nav>
  );
}
