import { Col, Row } from "react-bootstrap";
import { ProductInfo } from "../../../data/type";
import styled from './styles/Product.module.scss';
const Product = ({ productInfo }: { productInfo: ProductInfo }) => {
  return (
    <div className={styled.product}>
      <div className={styled.productImg}>
        <img src={productInfo.imageUrl} alt="" />
      </div>
      <div className={styled.title}>{productInfo.title}</div>
      <div className={styled.shortDescription}>
        <p>{productInfo.shortDescription}</p></div>
      <div className={styled.buttonGroup}>
        <Row>
          <Col>
            <a href="/">Chia sẻ</a>
          </Col>
          <Col>
            <a href="/">Đọc thêm</a>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Product;