import { Col, Row } from "react-bootstrap";
import styled from "../assets/style/Product.module.scss";
import ImageSlideShow from "../components/ImageSlideShow";
import { imageList } from "../data/constants";
import LeBruderNews from "../components/LeBruderNews";

const Product = () => {
  return (
    <div className={styled.product}>
      <div className={styled.scrollBanner}>
        <ImageSlideShow imageList={imageList} />
      </div>
      <div className={styled.productIntro}>
        <Row>
          <Col xs lg="7" className={styled.introImg}>
            <ImageSlideShow imageList={imageList} />
          </Col>
          <Col xs lg="5" className={styled.sectionTitle}>
            <div>
              <h5>Giới thiệu công trình</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse
                assumenda facere doloremque corrupti, animi nesciunt in, ea non,
                cum quae sunt velit architecto et quasi modi natus accusamus.
                Asperiores, eius.
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styled.productDetail}>
        <div className={styled.sectionTitle}>
          <h3>Thông tin chi tiết</h3>
        </div>
        <article>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste aliquid
          minima ad placeat, obcaecati, et eligendi laboriosam corporis sed
          totam architecto aliquam praesentium inventore pariatur est neque
          voluptates repellat voluptas. Lorem ipsum dolor sit, amet consectetur
          adipisicing elit. Sapiente dolorum facilis tempora quae, molestias
          earum voluptatibus harum rerum in explicabo! Id ipsa voluptatem
          ducimus, a sapiente ut deleniti molestias nisi. Lorem ipsum dolor sit,
          amet consectetur adipisicing elit. Sapiente dolorum facilis tempora
          quae, molestias earum voluptatibus harum rerum in explicabo! Id ipsa
          voluptatem ducimus, a sapiente ut deleniti molestias nisi.
        </article>
      </div>
      <div className={styled.product}>
        <div className={styled.sectionTitle}>
          <h3>Những ngôi nhà, kiểu dáng khác</h3>
        </div>
        <Row className={styled.article}>
          <Col className={styled.productArticle}>
            <div>Aticle 1</div>
          </Col>
          <Col className={styled.productArticle}>
            <div>Aticle 2</div>
          </Col>
          <Col className={styled.productArticle}>
            <div>Aticle 3</div>
          </Col>
        </Row>
      </div>
      <LeBruderNews />
    </div>
  );
};
export default Product;
