import styled from "./styles/InteriorProducts.module.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { HouseStyleInt } from "../../../data/type";
import HouseStyle from "./HouseStyle";
const PopularHouseStyle = () => {
  return (
    <div className={styled.interiorProduct} id="interiorProduct">
      <Carousel
        responsive={responsive}
        autoPlaySpeed={1000}
        infinite={true}
        rewind={true}
      >
        {productList.map((product) => (
          <div className={styled.product}>
            <HouseStyle styleInfo={product} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default PopularHouseStyle;

const productList: HouseStyleInt[] = [
  {
    imageUrl:
      "https://xaydungso.vn/webroot/img/images/thiet-ke-nha-ong-1-tang-1.jpg",
    title: "Nhà ống",
    shortDescription:
      "Mẫu nhà ống thường thiết kế rất đơn giản, không hề phức tạp như nhiều ngôi nhà khác, thời gian thi công mẫu nhà này rất nhanh do không phức tạp về mặt thiết kế",
  },
  {
    imageUrl:
      "https://vinhtuong.com/sites/default/files/2023-02/dac-diem-nha-cap-4.png",
    title: "Cấp 4 Gác lửng",
    shortDescription:
      "Nhà cấp 4 là loại nhà được xây dựng với kết cấu đơn giản, có niên hạn sử dụng tối đa 30 năm. Nhà cấp 4 có tường bao quanh nhà, ngăn cách các phòng làm bằng gạch",
  },
  {
    imageUrl:
      "https://trongoixaynha.com/wp-content/uploads/2021/08/xay-nha-2-tang-50m2-het-bao-nhieu-tien-1.jpg",
    title: "Nhà 2 tầng",
    shortDescription:
      "Những phối cảnh mẫu thiết kế mặt tiền nhà ống 2 tầng mái bằng và mái thái dưới đây sẽ mang đến những nét đẹp thẩm mỹ riêng biệt.",
  },
  {
    imageUrl:
      "https://arcviet.vn/wp-content/uploads/2017/10/mau-nha-pho-3-tang-1-tum-4x12m-hien-dai-1.jpg",
    title: "Nhà 3 tầng",
    shortDescription:
      "Nhà đẹp 3 tầng ở phố thiết kế theo phong cách hiện đại với mặt tiền rộng thoáng đang là xu hướng thịnh hành.",
  },
  {
    imageUrl:
      "https://toancanhbatdongsan.com.vn/uploads/images/2022/04/04/thiet-ke-san-vuon-nho-truoc-nha-cap-4-1649036914.jpg",
    title: "Nhà sân vườn",
    shortDescription:
      "Ngày nay, việc đưa thiết kế sân vườn nhỏ trước nhà, đặc biệt là những ngôi nhà cấp 4 đang trở thành xu hướng phổ biến của các gia đình Việt, những khu vườn này không chỉ tôn thêm vẻ đẹp cho không gian mà còn là nơi thư giãn yên tĩnh, thơ mộng mang đến bầu không khí trong lành, tốt cho sức khỏe.",
  },
  {
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKY5AvuT1WpRQUU6dzDQm3c_DZbHF_gXssB-ELu3oz8lDB0uiRB1QakOLYcKu_HhnSWuk&usqp=CAU",
    title: "Căn hộ",
    shortDescription:
      "Các mẫu thiết kế căn hộ của chúng tôi luôn đảm bảo tính thẩm mỹ, tối ưu về công năng sử dụng, tiết kiệm chi phí và hài hòa về phong thủy.",
  },
  {
    imageUrl:
      "https://sanvuonadong.vn/wp-content/uploads/2020/07/biet-thu-nha-vuon-dep-3-san-vuon-a-dong.jpg",
    title: "Biệt thự",
    shortDescription:
      "Một căn biệt thự đẹp không chỉ được đánh giá bởi hình thức kiến trúc mà còn là cả không gian cảnh quan sân vườn xung quanh ngôi nhà nữa. Những căn biệt thự sân vườn sẽ luôn được đánh giá cao và có sức hấp dẫn hơn.",
  },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};
