import { Col, Row } from "react-bootstrap";
import styled from "../assets/style/Footer.module.scss";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallIcon from "@mui/icons-material/Call";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import { ConstactInfo } from "../data/constants";
import { strimAllSpace } from "../lib/stringUtil";
const Footer = () => {
  return (
    <div className={styled.footer}>
      <div className="row">
        <div className="col-8">
          <div>
            <h1>Công ty xây dựng dân dụng, hạ tầng và du lịch E&C</h1>
            <div>
              Trụ sở chính: Số 64, Ngõ 102, Đường Trường Chinh, Phường Phương
              Mai, Quận Đống Đa, Hà Nội, Việt Nam
            </div>
            <div>
              CN - Hải Phòng: 154 Phố mới, Thủy Nguyên <span><CallIcon />
              <a href={`tel:${ConstactInfo.phone2}`}>{ConstactInfo.phone2}</a></span>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div>
            <CallIcon />
            <span><a href={`tel:${ConstactInfo.phone1}`}>{ConstactInfo.phone1}</a></span>
          </div>
          <div>
            Zalo<a href={`https://zalo.me/${strimAllSpace(ConstactInfo.phone2)}`}>{ConstactInfo.phone2}</a>
          </div>
          <div>
            <MailOutlineIcon />
            <span> <a href={`mailto:${ConstactInfo.infoEmail}`}>{ConstactInfo.infoEmail}</a></span>
          </div>
          <div>
            <BadgeOutlinedIcon /> <span> MST: xxx xxx xxxx</span>
          </div>
        </div>
      </div>
      <Row>
        <Col className={styled.copyright}>
          <div>© 2022 Copyright LE BRUDER JSC</div>
        </Col>
      </Row>
    </div>
  );
};
export default Footer;
