import { Col, Row } from "react-bootstrap";
import styled from "../assets/style/Home.module.scss";
import ImageSlideShow from "../components/ImageSlideShow";
import { imageList } from "../data/constants";
import LeBruderNews from "../components/LeBruderNews";
import InteriorProducts from "../features/home/components/InteriorProducts";
import PopularHouseStyle from "../features/home/components/PopularHouseStyle";
import ArticleSection from "../features/home/components/ArticleSection";
import { ProductInfo } from "../data/type";
const Home = () => {
  return (
    <div className={styled.homepage}>
      <div className={styled.scrollBanner}>
        <ImageSlideShow imageList={imageList} />
      </div>
      <div className={styled.highlight}>
        <Row>
          <Col>
            <div className={styled.item}>
              <a href="#p1">
                <div>
                  <h6>Xây dựng, thi công nhà trọn gói</h6>
                </div>
                <div>
                  <img
                    src="https://xaydungso.vn/webroot/img/images/xay-nha-tron-goi(4).jpg"
                    alt=""
                  />
                </div>
                <div>
                  <ul>
                    <li>Miễn phí thiết kế</li>
                    <li>Miễn phí GPXD</li>
                    <li>Bảo hành kết cấu</li>
                  </ul>
                </div>
              </a>
            </div>
          </Col>
          <Col>
            <div className={styled.item}>
              <a href="#interior">
                <div>
                  <h6>Tư vấn thiết kế, trang trí nội thất</h6>
                </div>
                <div>
                  <img
                    src="https://cbs.edu.vn/newsmultidata/dao-tao-chuyen-thiet-ke-noi-that-1.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <ul>
                    <li>Chi phí thiết kế rõ ràng</li>
                    <li>Đa dạng phong cách</li>
                    <li>Đảm bảo tiện dụng, an toàn</li>
                  </ul>
                </div>
              </a>
            </div>
          </Col>
          <Col>
            <div className={styled.item}>
              <a href="#p3">
                <div>
                  <h6>Lắp đặt sàn gỗ</h6>
                </div>
                <div>
                  <img
                    src="https://dongwhaflooring.com/wp-content/uploads/2021/12/banner-san-go-dongwha-vn.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <ul>
                    <li>Kháng khuẩn</li>
                    <li>Chống mối mọt</li>
                  </ul>
                </div>
              </a>
            </div>
          </Col>
        </Row>
      </div>

      <div className={styled.selfIntro}>
        <div className={styled.sectionTitle}>
          <h3>XÂY DỰNG DÂN DỤNG, HẠ TẦNG VÀ DU LỊCH (E&C)</h3>
        </div>
        <div>
          <ul>
            <li>Tư vấn thiết kế </li>
            <li>Thi công và hoàn thiện </li>
            <li>Cung cấp thiết bị, vật liệu chất lượng cao </li>
          </ul>
        </div>
        <div>
          <p>
            Với chiến lược phát triển bền vững cùng đội ngũ nhân sự chất lượng
            cao, có thương hiệu, uy tín cả trong và ngoài nước trên tinh thần
            đoàn kết, trách nhiệm và tôn trọng, chúng tôi cam kết mang đến cho
            khách hàng “chân giá trị” trong từng sản phẩm, dịch vụ.
          </p>
          <h6>Rất hân hạnh được hợp tác và đồng hành cùng quý vị!</h6>
        </div>
      </div>
      <div className={styled.product} id="p1">
        <div className={styled.sectionTitle}>
          <h3>Kiểu dáng nhà phổ biến</h3>
        </div>
        <PopularHouseStyle />
      </div>
      <div className={styled.product}>
        <div className={styled.sectionTitle}>
          <h3>Công trình thiết kế</h3>
        </div>
        <ArticleSection product={product}/>
      </div>
      <div className={styled.product} id="interior">
        <div className={styled.sectionTitle}>
          <h3>Công trình thi công</h3>
        </div>
        <ArticleSection product={constructionProduct}/>
      </div>
      <div className={styled.product}>
        <div className={styled.sectionTitle}>
          <h3>Công trình nội thất</h3>
        </div>
        <ArticleSection product={interiorProduct}/>
      </div>
      <div id="p3">
        <InteriorProducts />
      </div>
      <LeBruderNews />
    </div>
  );
};
export default Home;

// 
const product: ProductInfo = {
  imageUrl: "https://zameenblog.s3.amazonaws.com/blog/wp-content/uploads/2019/09/image-4-8.jpg",
  shortDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam laborum aspernatur nostrum, autem optio, quas molestias, officiis in veritatis velit eaque impedit voluptas dolorem excepturi. Amet voluptatem fugit nobis totam!",
  title: "Lorem ipsum dolor",
  shareLink: "",
  learnMoreLink: ""
}

const interiorProduct: ProductInfo = {
  imageUrl: "https://static3.bigstockphoto.com/8/5/3/large1500/358185578.jpg",
  shortDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam laborum aspernatur nostrum, autem optio, quas molestias, officiis in veritatis velit eaque impedit voluptas dolorem excepturi. Amet voluptatem fugit nobis totam!",
  title: "Lorem ipsum dolor",
  shareLink: "",
  learnMoreLink: ""
}

const constructionProduct: ProductInfo = {
  imageUrl: "https://img.freepik.com/premium-photo/new-house-construction-building-site_293060-52.jpg?w=1060",
  shortDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam laborum aspernatur nostrum, autem optio, quas molestias, officiis in veritatis velit eaque impedit voluptas dolorem excepturi. Amet voluptatem fugit nobis totam!",
  title: "Lorem ipsum dolor",
  shareLink: "",
  learnMoreLink: ""
}
