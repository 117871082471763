import { Col, Row } from "react-bootstrap";
import styled from "../assets/style/LeBruderNews.module.scss";
const LeBruderNews = () => {
  return (
    <div className={styled.lebruderNews}>
      <div><h4> Tin tức khác </h4></div>
      <Row>
        <Col>
          <Article />
        </Col>
        <Col>
          <Article />
        </Col>
        <Col>
          <Article />
        </Col>
      </Row>
    </div>
  );
};

export default LeBruderNews;

const Article = () => {
  return (
    <div className={styled.article}>
      <h6 className={styled.title}>
        <a href="/home">
          THAM QUAN NHÀ MÁY SẢN XUẤT SÀN GỖ CAO CẤP DONGWHA – THÁI NGUYÊN
        </a>
      </h6>
      <div className={styled.imageSection}>
        <img src="https://lebruder.com/wp-content/uploads/2023/03/2-600x490.png" />
      </div>
      <p className={styled.detail}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
    </div>
  );
};
