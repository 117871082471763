import { HouseStyleInt } from "../../../data/type";
import styled from './styles/HouseStyle.module.scss';
const HouseStyle = ({ styleInfo }: { styleInfo: HouseStyleInt }) => {
  return (
    <div className={styled.product}>
      <div className={styled.productImg}>
        <img src={styleInfo.imageUrl} alt="" />
      </div>
      <div className={styled.title}>{styleInfo.title}</div>
      <div className={styled.shortDescription}>
        <p>{styleInfo.shortDescription}</p></div>
    </div>
  );
};

export default HouseStyle;