import { Col, Row } from "react-bootstrap";
import styles from "../assets/style/Header.module.scss";
import Navbar from "./Navbar";

const Header = () => {
  return (
    <div className={styles.header}>
      <div className={styles.headerLine}>
      </div>
      <div>
        <img
          src={require("../assets/images/logo-LeBruder-01.png")}
          alt="logo"
        />
        <h1 className={styles.title}>LeBruder E&C</h1>
      </div>
      <Row>
        <Col className={styles.headerCol}>
          <h5>Tư vấn và thiết kế</h5>
          <ul>
            <li>Miễn phí tư vấn</li>
            <li>Thiết kế điện - nước, kiến trúc công trình</li>
            <li>Bóc tách báo giá thi công</li>
          </ul>
        </Col>
        <Col className={styles.headerCol}>
          <h5>Sửa chữa và cải tạo</h5>
          <ul>
            <li>Tư vấn giải pháp tối ưu</li>
            <li>Thiết kế, thi công cải tạo sửa chữa</li>
            <li>Hỗ trợ kỹ thuật, giải pháp xử lý sự cố</li>
          </ul>
        </Col >
        <Col className={styles.headerCol}>
          <h5>Hoàn thiện nội thất</h5>
          <ul>
            <li>Khảo sát hiện trạng, dựng mô hình 3D</li>
            <li>Tư vấn vật liệu nội thất</li>
            <li>Thi công hoàn thiện trọn gói</li>
          </ul>
        </Col>
        <Col className={styles.headerLastCol}>
          <h5>Gói thi công</h5>
          <ul>
            <li>Khảo sát hiện trạng, dựng mô hình 3D</li>
            <li>Thi công phần thô</li>
            <li>Thi công hoàn thiện trọn gói</li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <Navbar />
        </Col>
      </Row>
    </div>
  );
};
// const Header = () => {
//   return (
//     <div className={styles.header}>
//       <Row>
//         <div className="col-5">
//           <div className={styles.logo}>
//             <img
//               src={require("../assets/images/logo-LeBruder-01.png")}
//               alt="logo"
//             />
//           </div>
//         </div>
//         <div className="col-6">
//           <div className={styles.headerText}>Chúng tôi là Le Bruder E&C</div>
//         </div>
//       </Row>
//       <Row>
//         <Col>
//           <Navbar />
//         </Col>
//       </Row>
//     </div>
//   );
// };
export default Header;
