import Carousel from "nuka-carousel";
import { ImageObject } from "../data/type";
function ImageSlideShow({ imageList }: { imageList: ImageObject[] }) {
  console.log(imageList);
  return (
    <>
      <Carousel wrapAround={true} cellAlign="center" autoplay={true}>
        {imageList.map((image, index) => (
          <div key={index}>
            <img src={image.path} />
          </div>
        ))}
      </Carousel>
    </>
  );
}
export default ImageSlideShow;
