import { ProductInfo } from "../../../data/type";
import styled from "./styles/HomeArticle.module.scss";
const HomeArticleIntro = ({homeArticleInfo}: {homeArticleInfo: ProductInfo}) => {
  return (
    <div className={styled.homeAricle}>
      <div className={styled.image}>
        <img  src={homeArticleInfo.imageUrl} alt="" />
      </div>
      <div className={styled.title}>
        <h6>{homeArticleInfo.title}</h6>
      </div>
      <div>
        <p>
          {homeArticleInfo.shortDescription}
        </p>
      </div>
    </div>
  );
};
export default HomeArticleIntro;

