import Home from "./pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useLocalStorage } from "./features/notes/hooks/useLocalStorage";
import Footer from "./layouts/Footer";
import Header from "./layouts/Header";
import Product from "./pages/Product";

export type Tag = {
  id: string;
  label: string;
};

function App() {
  const [tags, setTags] = useLocalStorage<Tag[]>("TAGS", []);

  return (
    <>
      <div>
        <Header />
      </div>
      <Container>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          {/* <Route path="/:id">
            <Route index element={<h1>Show</h1>} />
            <Route path="/:id/edit" element={<h1>Edit</h1>} />
          </Route> */}
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </Container>
      <div>
        <Footer />
      </div>
    </>
  );
}

export default App;
