import { Col, Row } from "react-bootstrap";
import HomeArticleIntro from "./HomeArticleIntro";
import styled from "./styles/ArticleSectionStyle.module.scss";
import { ProductInfo } from "../../../data/type";
const ArticleSection = ({ product }: { product: ProductInfo }) => {
  return (
    <div className={styled.articleSectionStyle}>
      <Row className={styled.paddingBottom}>
        <Col>
          <HomeArticleIntro homeArticleInfo={product}/>
        </Col>
        <Col>
          <HomeArticleIntro homeArticleInfo={product}/>
        </Col>
        <Col>
          <HomeArticleIntro homeArticleInfo={product}/>
        </Col>
      </Row>
      <Row>
        <Col>
          <HomeArticleIntro homeArticleInfo={product}/>
        </Col>
        <Col>
          <HomeArticleIntro homeArticleInfo={product}/>
        </Col>
        <Col>
          <HomeArticleIntro homeArticleInfo={product}/>
        </Col>
      </Row>
    </div>
  );
};

export default ArticleSection;