import styled from "./styles/InteriorProducts.module.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Product from "./Product";
import { ProductInfo } from "../../../data/type";
const InteriorProducts = () => {
  return (
    <div className={styled.interiorProduct} id="interiorProduct">
      <div className={styled.sectionTitle}>
        <h3>Sản phẩm trong nhà</h3>
      </div>
      <Carousel
        responsive={responsive}
        autoPlaySpeed={1000}
        infinite={true}
        rewind={true}
      >
        <div className={styled.product}>
          <Product productInfo={productList[0]} />
        </div>
        <div className={styled.product}>
          <Product productInfo={productList[1]} />
        </div>
        <div className={styled.product}>
          <Product productInfo={productList[0]} />
        </div>
        <div className={styled.product}>
          <Product productInfo={productList[1]} />
        </div>
        <div className={styled.product}>
          <Product productInfo={productList[0]} />
        </div>
      </Carousel>
    </div>
  );
};

export default InteriorProducts;

const productList: ProductInfo[] = [
  {
    imageUrl:
      "https://phaochinhua.net/thumb/550x500/1/90/upload/product/phao-pu-03-1579.jpg",
    title: "Vật liệu trang trí PU",
    shortDescription:
      "PU là nguyên vật liệu trung gian dùng để sản xuất ra các sản phẩm được các nước phát triển sử dụng trong các ngành công nghiệp và các vật dụng, hàng hóa trong đời sống hằng ngày.",
    shareLink: "",
    learnMoreLink: "",
  },
  {
    imageUrl:
      "http://hanasan.vn/wp-content/uploads/2022/08/banner-san-go-han-quoc-dongwha-1024x384.jpg",
    title: "SÀN GỖ HÀN QUỐC – DONGWHA",
    shortDescription:
      "Sàn Hàn Quốc được sản xuất từ các nguyên liệu trong nước và nhập khẩu từ Mỹ, Canada, Châu Âu. Với kỹ thuật sản xuất hiện đại và quy trình kiểm tra chất lượng khắt khe, sàn gỗ Hàn Quốc có độ bền cao, khả năng chống trầy xước và chống mối mọt vượt trội",
    shareLink: "",
    learnMoreLink: "",
  },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};
